import Link from '@components/ui/Link'
import { Crumb } from '@lib/types'
import cn from 'clsx'
import { useTranslation } from 'next-i18next'
import { Fragment } from 'react'

interface Props {
  crumbs: Crumb[]
  lastCrumH1?: boolean
  // generateBreadCrumbJson?: boolean
}

const Breadcrumbs = ({
  crumbs,
  lastCrumH1,
}: // generateBreadCrumbJson = true,
Props) => {
  const { t } = useTranslation('common')

  if (!crumbs || !crumbs.length) return <></>

  return (
    <>
      <div
        className={cn(
          'label flex w-full gap-3 overflow-x-scroll text-dark-grey scrollbar-hide lg:overflow-x-hidden'
        )}
      >
        {crumbs.map((crumb, index) => {
          return (
            <Fragment key={crumb?.label}>
              {index === crumbs.length - 1 ? (
                <>
                  {crumb?.label && lastCrumH1 && (
                    <h1 className="text-charcoal body-small currentLink whitespace-nowrap capitalize">
                      {crumb?.label}
                    </h1>
                  )}
                  {crumb?.label && !lastCrumH1 && (
                    <span className="text-charcoal body-small currentLink whitespace-nowrap capitalize">
                      {crumb?.label}
                    </span>
                  )}
                </>
              ) : (
                <>
                  <Link key={crumb?.label} href={crumb?.link || '/'}>
                    <span className="whitespace-nowrap capitalize opacity-50">
                      {t(crumb?.label.toLowerCase()) ?? crumb?.label}
                    </span>
                  </Link>
                  <span className="text-doveGrey linkDivider">/</span>
                </>
              )}
            </Fragment>
          )
        })}
      </div>
    </>
  )
}

export default Breadcrumbs
