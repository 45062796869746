import { ChevronRight, Cross } from '@components/icons'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'

interface menuItem {
  linkTitle: string
  link: string
}

interface Props {
  title: string
  menuItems: menuItem[]
  onClose: () => void
}

const MobileAuxSideBar: FC<Props> = ({ title, menuItems, onClose }) => {
  const { asPath } = useRouter()
  // className={cn({ selected: asPath === i?.link })}
  if (menuItems.length === 0) return <></>
  return (
    <>
      <div className="fixed-component-shadow">
        <div>
          <button
            onClick={onClose}
            className="flex w-full justify-end py-4 px-4"
          >
            <Cross />
          </button>
        </div>
        <div className="align-center px-20 pb-8 text-center">
          <div className="text-body2 font-bold uppercase text-dark-grey">
            {title}
          </div>
        </div>
        <ul className="px-6">
          {menuItems.map((i, index) => {
            return (
              <li key={index}>
                <Link
                  href={i?.link || '/'}
                  passHref
                  className={cn(
                    'flex items-center justify-between pb-6 text-body1 font-light text-brand-dark-grey',
                    {
                      'body-1-bold text-brand-dark-grey':
                        asPath === i?.link ||
                        (index == 0 &&
                          !menuItems?.find(
                            (item: any) => item?.link == asPath
                          )),
                    }
                  )}
                >
                  {i?.linkTitle}
                  <ChevronRight width="20" height="20" />
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default MobileAuxSideBar
