import { AUX_PAGE_NAVIGATION_HANDLE } from '@lib/helpers/general'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'

interface auxItems {
  linkTitle: string
  link: string
}

interface Props {
  auxItems: auxItems[]
  sideNavigation: string
}

const AuxSidebar: FC<Props> = ({ auxItems, sideNavigation }) => {
  const { asPath } = useRouter()
  const { t } = useTranslation('common')

  if (auxItems.length === 0) return <></>

  return (
    <div>
      <div className="sticky top-24 ">
        <ul className="">
          {auxItems.map((i, index) => {
            return (
              <li key={index}>
                <Link
                  href={i?.link || '/'}
                  passHref
                  className={cn(
                    'inline-block cursor-pointer px-4 py-4 text-body1 font-light text-brand-dark-grey lg:w-76',
                    {
                      'bg-white text-body1 font-bold text-brand-dark-grey':
                        asPath === i?.link,
                    }
                  )}
                >
                  {i?.linkTitle}
                </Link>
              </li>
            )
          })}
        </ul>
        {sideNavigation && sideNavigation === AUX_PAGE_NAVIGATION_HANDLE && (
          <>
            <div className="border-grey block w-full border-t lg:w-76"></div>
            <div className="py-6 px-4 text-body1 text-dark-grey">
              {t('cant-find-looking-for')}{' '}
              <Link
                href="/contact-us"
                passHref
                className="cursor-pointer underline decoration-1 underline-offset-1"
              >
                {t('contact-us')}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AuxSidebar
