type AcknowledgementItemProps = {
  title: string
  value: string
}

export default function AcknowledgementItem({
  title,
  value,
}: AcknowledgementItemProps): JSX.Element {
  return (
    <div className="body-2 flex">
      <div className="body-2-bold w-20">{title}</div>
      <div>{value}</div>
    </div>
  )
}
